import { useAtomValue } from "jotai"

import { sessionAtom, sessionStatusAtom, sessionCustomerAtom, sessionIdAtom, loginAtom, logoutAtom, registerAtom } from "~/atoms/session"

/**
 * Response from /api/session endpoint.
 */
export function useSession() {
  return useAtomValue(sessionAtom)
}

/**
 * Status of session.
 */
export function useSessionStatus() {
  return useAtomValue(sessionStatusAtom)
}

/**
 * Customer from session.
 */
export function useSessionCustomer() {
  return useAtomValue(sessionCustomerAtom)
}

/**
 * Session ID from session.
 */
export function useSessionId() {
  return useAtomValue(sessionIdAtom)
}

/**
 * Login a session.
 */
export function useLogin() {
  const { mutateAsync } = useAtomValue(loginAtom)
  return mutateAsync
}

/**
 * Logout the current session.
 */
export function useLogout() {
  const { mutateAsync } = useAtomValue(logoutAtom)
  return mutateAsync
}

/**
 * Register a new account.
 */
export function useRegister() {
  const { mutateAsync } = useAtomValue(registerAtom)
  return mutateAsync
}
