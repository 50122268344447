import { WithGatsbyImages } from "@ts/components"
import { SettingSubscribe } from "@ts/sanity"
import { graphql, useStaticQuery } from "gatsby"
import { useLocalisation } from "./useLocalisation"

export type UseSubscribe = {
  subscribe: WithGatsbyImages<Partial<SettingSubscribe>, "images">
}

export const useSubscribe = (): UseSubscribe => {
  const data = useStaticQuery(graphql`
    query SANITY_SETTINGS_SUBSCRIBE {
      allSubscribeSettings: allSanitySettingSubscribe {
        edges {
          node {
            i18n_lang
            i18n_base {
              id
            }
            options {
              title
              tag
            }
            emailsInterestedInNotSetupMessage
            emailTitle
            emailContent: _rawEmailContent(resolveReferences: { maxDepth: 1 })
            emailTerms: _rawEmailTerms(resolveReferences: { maxDepth: 5 })
            successTitle
            successMessage: _rawSuccessMessage(resolveReferences: { maxDepth: 1 })
            successCTA {
              ...InternalLink
            }
          }
        }
      }
    }
  `)

  const { findNodeByLocale } = useLocalisation()
  const { allSubscribeSettings } = data
  const subscribe = findNodeByLocale<SettingSubscribe>(allSubscribeSettings)

  return {
    subscribe,
  }
}
