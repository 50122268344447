import React from "react"
import tw from "twin.macro"
import { Script } from "gatsby"
import { useApp } from "@hooks/useApp"
import { useSettings } from "@hooks/useSettings"
import { useSite } from "@hooks/useSite"

import "./LiveChat.css"

const Container = tw.div`
  fixed bottom-maxi right-3 block w-auto h-auto z-100
`

export const LiveChatScripts: React.FC = () => {
  const {
    config: {
      services: { powerfront },
    },
  } = useApp()
  const { locale } = useSite()

  const { liveChat } = useSettings()

  if (!liveChat || !liveChat.enabled) return null

  if (process.env["GATSBY_FEATURE_ZENDESK_CHAT"] === "true") {
    let key = "1b65676d-33b7-40f5-a795-f66257e22f01" // Default to Intl
    if (locale == "en-AU") {
      key = "5bcd349c-ca03-435d-9832-574e32dc474d"
    } else if (locale == "en-NZ") {
      key = "6c30112b-9019-4894-b664-4ef27e0d2b3e"
    }
    return <Script id="ze-snippet" strategy="idle" onLoad={handleZendeskLoad} src={`https://static.zdassets.com/ekr/snippet.js?key=${key}`} />
  }

  if ("inside" === liveChat.type && powerfront.chatScriptUrl) {
    return <Script strategy="idle" src={powerfront.chatScriptUrl} onLoad={handleInsideLoad} data-testid="inside-chat-script" />
  }

  if ("salesforce" === liveChat.type) {
    return (
      <Container id="live-chat-container">
        <Script
          strategy="idle"
          data-testid="salesforce-chat-script"
          src="https://baredfootwear.my.salesforce.com/embeddedservice/5.0/esw.min.js"
          onLoad={() => handleSalesforceLoad({ deploymentId: liveChat.deployment_id })}
        />
      </Container>
    )
  }

  return null
}

function handleZendeskLoad() {
  window["LiveChat"] = {
    open: () => {
      if (window["zE"]) {
        window["zE"]("messenger", "open")
      }
    },
  }
}

function handleInsideLoad() {
  window["LiveChat"] = {
    open: () => {
      if (window["insideFrontInterface"]?.openChatPane) {
        window["insideFrontInterface"].openChatPane()
      }
    },
  }
}

function handleSalesforceLoad({ deploymentId }: { deploymentId?: string }) {
  const embedded_svc = window["embedded_svc"]

  if (!embedded_svc) {
    console.error("Attempted to bootstrap Salesforce without the lib.")
    return
  }

  const sessionData = window["_salesforceData"]

  embedded_svc.settings.displayHelpButton = true
  embedded_svc.settings.language = "en-AU"
  embedded_svc.settings.defaultMinimizedText = "Live Chat"
  embedded_svc.settings.disabledMinimizedText = "Get Help"
  embedded_svc.settings.extraPrechatFormDetails = [
    {
      label: "BaseUrl",
      value: document.location.origin,
      displayToAgent: false,
      transcriptFields: ["BaseUrl__c"],
    },
  ]

  const prepopulatedPrechatFields: SalesforcePrePopulatedFields = {
    BaseUrl: document.location.origin,
    FirstName: null,
    LastName: null,
    Email: null,
  }

  if (sessionData?.user) {
    const name = sessionData?.user?.name?.split(" ")
    prepopulatedPrechatFields.FirstName = name?.[0]
    prepopulatedPrechatFields.LastName = name?.[1]
    prepopulatedPrechatFields.Email = sessionData?.user?.email
  }

  const targetElement = document.getElementById("live-chat-container")
  embedded_svc.settings.prepopulatedPrechatFields = prepopulatedPrechatFields
  embedded_svc.settings.enabledFeatures = ["LiveAgent"]
  embedded_svc.settings.entryFeature = "LiveAgent"

  if (targetElement) {
    embedded_svc.settings.targetElement = document.getElementById("live-chat-container")
  }

  const gslbBaseURL = null

  embedded_svc.init(
    "https://baredfootwear.my.salesforce.com",
    "https://baredfootwear.my.salesforce-sites.com/liveAgentSetupFlow",
    gslbBaseURL,
    "00D5g00000HNq8K",
    "ChatTeam",
    {
      deploymentId,
      buttonId: "5735g0000000lXF",
      isOfflineSupportEnabled: true,
      eswLiveAgentDevName: "ChatTeam",
      baseLiveAgentContentURL: "https://c.la2-c1-hnd.salesforceliveagent.com/content",
      baseLiveAgentURL: "https://d.la2-c1-hnd.salesforceliveagent.com/chat",
    }
  )

  window["LiveChat"] = {
    open: () => {
      embedded_svc.liveAgentAPI.startChat()
    },
  }

  window["LiveChatService"] = embedded_svc
}

interface SalesforcePrePopulatedFields {
  BaseUrl: string
  Email?: string | null
  LastName?: string | null
  FirstName?: string | null
}

declare global {
  interface Window {
    LiveChat?: any
    LiveChatService?: any
    embedded_svc?: any
    insideFrontInterface?: any
  }
}
