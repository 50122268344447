import { useApp } from "@hooks/useApp"
import { useCheckout, useCheckoutContext } from "@hooks/useCheckout"
import { useCore } from "@hooks/useCore"
import { useCustomerAccessToken, useCustomerContext, useCustomerSession } from "@hooks/useCustomer"
import { useLocalisationContext } from "@hooks/useLocalisation"
import { useSale } from "@hooks/useSale"
import { globalHistory } from "@reach/router"
import React, { useEffect } from "react"

import type { GatsbyLocation } from "~/types/gatsby"

interface InputProps {
  location: GatsbyLocation
  navigationRootPath?: string
  children: React.ReactNode
}

type OutputProps = InputProps

export const withApp =
  (Component: React.FC<OutputProps>) =>
  ({ location, children, navigationRootPath }: InputProps) => {
    const {
      helpers: { storage },
    } = useCore()
    const {
      config: {
        settings: { keys },
      },
    } = useApp()
    const { checkout } = useCheckoutContext()
    const { getCheckout, createCheckout } = useCheckout()
    const { customer } = useCustomerContext()
    const { getCustomer } = useCustomerAccessToken()
    const { createSessionId } = useCustomerSession()
    const { isSaleActive } = useSale()
    const isSale = isSaleActive()
    const { currentLocale, isResolvingPreferredLocale } = useLocalisationContext()
    const { defaultCountryCode } = currentLocale || {}

    useEffect(() => {
      return globalHistory.listen(({ location }) => {
        const scrollRestorationRoutes = ["/products/", "/collections/"]

        if (!scrollRestorationRoutes.some(route => location.pathname.match(route))) {
          storage.remove(keys.collection_scroll_position)
        }
      })
    }, [])

    useEffect(() => {
      const checkoutCountryCode = checkout?.buyerIdentity?.countryCode
      if (checkoutCountryCode === defaultCountryCode || isResolvingPreferredLocale) {
        return
      }

      createCheckout(defaultCountryCode)
    }, [checkout, defaultCountryCode, isResolvingPreferredLocale])

    useEffect(() => {
      getCheckout(isSale)
    }, [isSale])

    useEffect(() => {
      if (customer) {
        return
      }

      getCustomer()
      createSessionId()
    }, [customer])

    return (
      <Component location={location} navigationRootPath={navigationRootPath}>
        {children}
      </Component>
    )
  }
