import { useAnalytics } from "@hooks/useAnalytics"
import { useCore } from "@hooks/useCore"
import { useCustomerContext } from "@hooks/useCustomer"
import { useFunctions } from "@hooks/useFunctions"
import { useRichText } from "@hooks/useRichText"
import { useRoutes } from "@hooks/useRoutes"
import { useSubscribe } from "@hooks/useSubscribe"
import { globalHistory } from "@reach/router"
import { type ComponentProps, type ResolvedLink } from "@ts/components"
import { type SubscribeOption } from "@ts/sanity"
import { CustomerMarketingOptInLevel, CustomerSmsMarketingState } from "@ts/shopify-admin"
import React, { ChangeEvent, ReactNode, memo, useEffect, useState, type FC, type FormEvent } from "react"

export type SubscribeFormOutputProps = {
  data: any
  emailsInterestedInNotSetupMessage: string
  emailTitle: string
  description: ReactNode | ReactNode[]
  errorInterestedInIsEmpty: boolean
  errors: any[]
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleInvalid: (event: ChangeEvent<HTMLInputElement>) => void
  handleSubmit: (event: FormEvent) => Promise<void>
  loading: boolean
  options: SubscribeOption[]
  privacy: ResolvedLink
  requiredFields: {
    [key: string]: boolean
  }
  success: boolean
  successButton: any
  successMessage: string
  successTitle: string
  tags: string[]
  terms: string
  toggleTag: (tag: string) => void
}

type ChildComponentContext = {
  trackingName: "Footer"
}

type FormData = {
  email?: string
  phone?: string
}

export const withSubscribeForm = (Component: FC<SubscribeFormOutputProps>, context: ChildComponentContext) =>
  memo(({ name = "SubscribeForm" }: ComponentProps) => {
    const {
      helpers: { decodeBase64 },
    } = useCore()
    const { parseContent } = useRichText()
    const { trackSignup } = useAnalytics()
    const { subscribe } = useSubscribe()
    const { customerSubscribe, errors, setErrors } = useFunctions()
    const { customer } = useCustomerContext()
    const { linkResolver } = useRoutes()

    const [data, setData] = useState<FormData>({ email: "", phone: "" })
    const [tags, setTags] = useState(["email_pop_up", "newsletter"])
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [requiredFields, setRequiredFields] = useState({})
    const [errorInterestedInIsEmpty, setErrorInterestedInIsEmpty] = useState(false)

    const hasValidInterests = () => {
      return !!tags.find(tag => tag === "men" || tag === "women")
    }

    const handleSubmit = async (event: FormEvent) => {
      setLoading(true)
      event.preventDefault()
      setErrorInterestedInIsEmpty(false)
      if (!hasValidInterests()) {
        setLoading(false)
        return setErrorInterestedInIsEmpty(true)
      }
      const hasMarketingPreference = typeof customer?.acceptsMarketing !== "undefined"
      const acceptsMarketing = hasMarketingPreference ? customer?.acceptsMarketing : !!data?.email
      const smsMarketingConsent = data?.phone
        ? {
            marketingState: CustomerSmsMarketingState.SUBSCRIBED,
            marketingOptInLevel: CustomerMarketingOptInLevel.SINGLE_OPT_IN,
          }
        : undefined
      const { customer: newCustomer, userErrors } = await customerSubscribe(
        data?.email,
        tags,
        acceptsMarketing,
        smsMarketingConsent,
        customer?.id ? decodeBase64(customer?.id) : null,
        customer?.email,
        data?.phone
      )

      if (newCustomer?.id) {
        setSuccess(true)
        trackSignup(context?.trackingName)
      }

      setErrors(userErrors)
      setLoading(false)
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { type, name, value, checked },
      } = event
      setData(prevState => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }))

      handleInvalid(event)
    }

    const handleInvalid = (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      const {
        target: { name, value },
      } = event

      setRequiredFields(prevState => ({
        ...prevState,
        [name]: !value,
      }))
    }

    const toggleTag = tag => {
      const index = tags.indexOf(tag)
      index === -1 ? setTags([...tags, tag]) : setTags(tags.filter(a => a !== tag))
    }

    const { emailTitle, options, successTitle } = subscribe || {}
    const emailsInterestedInNotSetupMessage = subscribe?.emailsInterestedInNotSetupMessage
    const privacy = linkResolver(subscribe?.privacy)
    const successButton = linkResolver(subscribe?.successCTA)
    const successMessage = parseContent(subscribe?.successMessage)
    const terms = parseContent(subscribe?.emailTerms)
    const description = parseContent(subscribe?.emailContent)

    useEffect(() => {
      return globalHistory.listen(({ action }) => {
        if (action === "PUSH") {
          setSuccess(false)
          setErrors([])
        }
      })
    }, [setSuccess, setErrors])

    Component.displayName = name

    return (
      <Component
        data={data}
        emailsInterestedInNotSetupMessage={emailsInterestedInNotSetupMessage}
        emailTitle={emailTitle}
        description={description}
        errorInterestedInIsEmpty={errorInterestedInIsEmpty}
        errors={errors}
        handleChange={handleChange}
        handleInvalid={handleInvalid}
        handleSubmit={handleSubmit}
        loading={loading}
        options={options}
        privacy={privacy}
        requiredFields={requiredFields}
        success={success}
        successButton={successButton}
        successMessage={successMessage}
        successTitle={successTitle}
        tags={tags}
        terms={terms}
        toggleTag={toggleTag}
      />
    )
  })
