import { useAtomValue, ExtractAtomValue } from "jotai"

import { rootLoaderDataAtom, siteLoaderDataAtom } from "~/atoms/loaders"

/**
 * Return data from a specific loader.
 * @see https://remix.run/docs/en/main/hooks/use-route-loader-data
 */
export function useRouteLoaderData(route: "root"): ExtractAtomValue<typeof rootLoaderDataAtom>
export function useRouteLoaderData(route: "routes/$site"): ExtractAtomValue<typeof siteLoaderDataAtom>
export function useRouteLoaderData(
  route: "root" | "routes/$site"
): ExtractAtomValue<typeof rootLoaderDataAtom> | ExtractAtomValue<typeof siteLoaderDataAtom> {
  const rootLoaderData = useAtomValue(rootLoaderDataAtom)
  const siteLoaderData = useAtomValue(siteLoaderDataAtom)

  if ("root" === route) return rootLoaderData

  if ("routes/$site" === route) return siteLoaderData

  throw new Error(`Loader route ${route} not recognized.`)
}
