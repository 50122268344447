import React from "react"
import { Provider as JotaiProvider } from "jotai"

import * as Sentry from "@sentry/gatsby"

import { AtomHydrator } from "~/atoms/AtomHydrator"

import type { GatsbyBrowser } from "~/types/gatsby"

import { App } from "~/components/App/App"
import { Redirect } from "~/components/Redirect"
import { GeofencingModal } from "~/components/GeofencingModal"
import { SessionConsumer } from "~/components/SessionConsumer"
import { ImpactAnalytics } from "~/components/Impact/ImpactAnalytics"
import { LiveChatScripts } from "~/components/LiveChat/LiveChatScripts"

import { GlobalProviders } from "~/providers/global"
import { LocalisedProviders } from "~/providers/localised"

import { LegacyLocaleProvider } from "~/providers/LegacyLocaleProvider"

import type { ServerResponseContext, RedirectResponse } from "~/lib/server"

import { rootLoaderDataAtom, siteLoaderDataAtom } from "~/atoms/loaders"

import "./src/tailwind.css"

import config from "./config.default.mjs"

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => (
  <JotaiProvider>
    <LegacyLocaleProvider>
      <GlobalProviders>
        {element}
        <ImpactAnalytics />
        <SessionConsumer />
      </GlobalProviders>
    </LegacyLocaleProvider>
  </JotaiProvider>
)

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props: {
    location,
    serverData,
    pageContext: { navigationRootPath },
  },
}) => {
  const serverResponseContext = (serverData as any)?.context as ServerResponseContext
  if (!serverResponseContext) {
    throw new Error(
      "Could not resolve server response context in gatsby-browser.tsx. Ensure your page exports a getServerData function and you are using the respond method from server context."
    )
  }

  // If server returned a redirect, do not render.
  if (serverData && 301 === (serverData as any).status) {
    const location = (serverData as RedirectResponse["props"]).location
    return <Redirect href={location} />
  }

  return (
    <AtomHydrator
      dangerouslyForceHydrate={true}
      atomValues={[
        [rootLoaderDataAtom, serverResponseContext.rootData],
        [siteLoaderDataAtom, serverResponseContext.siteLoaderData],
      ]}
    >
      <LegacyLocaleProvider>
        <LocalisedProviders>
          <App location={location} navigationRootPath={navigationRootPath}>
            {element}
          </App>
          <GeofencingModal />
          <LiveChatScripts />
        </LocalisedProviders>
      </LegacyLocaleProvider>
    </AtomHydrator>
  )
}

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location, prevLocation }) => {
  if (
    location?.pathname !== prevLocation?.pathname &&
    ((prevLocation?.pathname.includes(config.settings.routes.COLLECTION) && location?.pathname.includes(config.settings.routes.COLLECTION)) ||
      (prevLocation?.pathname.includes(config.settings.routes.COLLECTION) && location?.pathname.includes(config.settings.routes.SEARCH)) ||
      (prevLocation?.pathname.includes(config.settings.routes.SEARCH) && location?.pathname.includes(config.settings.routes.COLLECTION)))
  )
    window.dispatchEvent(new Event(`refreshSearch`))
}

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  if (!window) {
    return
  }

  window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
    const reasonsToForceReload = [/loading chunk \d* failed./i, /we couldn't find the correct component chunk with the name component--src/i]

    const hasValidErrors = reasonsToForceReload.reduce((prev, curr) => prev || curr.test(event.reason), false)

    if (!hasValidErrors) {
      return
    }

    const forceReloadKey = "__fr"
    const queryStringParams = new URLSearchParams(window.location.search)
    const queryStringKeyValues = [...queryStringParams.entries()]
    const hasForceReloadParam = queryStringKeyValues.find(([key]) => key === forceReloadKey)
    const newQueryString = [...queryStringKeyValues, [forceReloadKey, Date.now()]].reduce(
      (prev, [key, value]) => `${prev}${prev === "?" ? "" : "&"}${key}=${value}`,
      "?"
    )

    if (!hasForceReloadParam) {
      Sentry.captureMessage("Chunk load error failed, attempting reload", {
        level: "warning",
        extra: {
          event,
        },
      })
      window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}${newQueryString}`
    } else {
      Sentry.captureMessage("Chunk load error force reload failed", {
        level: "fatal",
        extra: {
          event,
        },
      })
    }
  })
}
