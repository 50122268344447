import { SkipLink } from "@components/Accessibility/SkipLink/SkipLink"
import CartDrawer from "@components/Cart/Drawer/CartDrawer"
import CountdownTimer from "@components/CountdownTimer/CountdownTimer"
import FooterSection from "@components/Footer/FooterSection"
import Header from "@components/Header/Header"
import React from "react"
import AppStyles from "./AppStyles"
import { withApp } from "./withApp"

export const App = withApp(({ children, location, navigationRootPath }) => (
  <>
    <AppStyles />
    <SkipLink href="#content-main">Skip Navigation</SkipLink>
    <Header location={location} navigationRootPath={navigationRootPath} />
    <main className="flex-1 pt-[86px] md:pt-[122px]" id="content-main">
      <CountdownTimer />
      {children}
    </main>
    <FooterSection />
    <CartDrawer />
  </>
))
