import { CheckoutContext } from "@providers/global/checkout"
import * as Sentry from "@sentry/gatsby"
import { ElasticVariant } from "@usereactify/search"
import { useCallback, useContext, useState } from "react"
import { SearchResultProductPresentmentPriceRanges } from "../types/search"
import { Checkout, CheckoutUserError, MoneyV2 } from "../types/shopify-storefront"
import { useAnalytics } from "./useAnalytics"
import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useCurrency } from "./useCurrency"
import { useGooglePlacesContext } from "./useGooglePlaces"
import { useLocalisationContext } from "./useLocalisation"
import { useLocation } from "./useLocation"
import { useSale } from "./useSale"
import { useShop } from "./useShop"
import { useShopify } from "./useShopify"
import { NormalisedCheckout } from "@ts/components"

export type UseCheckout = {
  applyDiscountCode: (discountCode?: string) => Promise<void>
  applyGiftCardCode: (giftCardCode?: string) => Promise<void>
  calculateLineItemsQuantityTotal: () => number | undefined
  checkout: Partial<Checkout>
  checkoutUrl: string
  createCheckout: (currencyCode?: string) => Promise<void>
  duplicateCheckoutByCountryCode: (currencyCode?: string) => Promise<void>
  errors?: CheckoutUserError[]
  getCheckout: (save: boolean) => Promise<Partial<Checkout>>
  getShippingRates: () => Promise<void>
  getFormattedSalePriceFromTagOrRange: (
    presentmentPrice: MoneyV2 | SearchResultProductPresentmentPriceRanges,
    saleTag?: string,
    compareAtPrice?: MoneyV2,
    variants?: ElasticVariant[]
  ) => {
    price: string
    salePrice?: string | null
  }
  loading: boolean
  loadingAttributes: boolean
  rawCheckout: Checkout
  setCheckout: (checkout: NormalisedCheckout) => void
  setRawCheckout: (checkout: Checkout) => void
  updateAttributes: (input: Record<string, any>) => Promise<void>
  updateCustomer: (accessToken?: string) => void
  updatedAttributes: boolean
  updateShippingAddress: (input: Record<string, any>) => Promise<void>
  updateShippingLine: (handle: string) => Promise<void>
}

export const useCheckoutContext = () => {
  const checkoutData = useContext(CheckoutContext)
  return { ...checkoutData }
}

export const useCheckout = (): UseCheckout => {
  const {
    helpers: { storage },
    graphql: {
      mutations: {
        CHECKOUT_CREATE,
        CHECKOUT_ATTRIBUTES_UPDATE,
        CHECKOUT_SHIPPING_ADDRESS_UPDATE,
        CHECKOUT_DISCOUNT_APPLY,
        CHECKOUT_GIFTCARDS_APPEND,
        CHECKOUT_CUSTOMER_ASSOCIATE,
        CHECKOUT_SHIPPING_LINE_UPDATE,
      },
      queries: { GET_CHECKOUT, GET_SHIPPING_RATES },
    },
  } = useCore()

  const {
    config: {
      app: { url },
      settings: { keys },
    },
  } = useApp()

  const { formatCurrency } = useCurrency()
  const { checkout, setCheckout: saveCheckout, rawCheckout, setRawCheckout } = useCheckoutContext()
  const { clearAddress } = useGooglePlacesContext()
  const { checkoutNormaliser, useMutation, useQuery } = useShopify()
  const { getShop } = useShop()
  const { shopifyStore, shopifyStoreDomain } = useLocation()
  const { contextCountry: countryCode } = useLocalisationContext()
  const { decorateUrl } = useAnalytics()
  const { isSaleActive } = useSale()
  const isSale = isSaleActive()

  const [loadingAttributes, setLoadingAttributes] = useState(false)
  const [updatedAttributes, setUpdatedAttributes] = useState(false)
  const [errors, setErrors] = useState(null)
  const [loading, setLoading] = useState(false)
  const checkoutId = storage.get(keys?.checkout)
  const checkoutUrl = decorateUrl(checkout?.webUrl ? checkout.webUrl.replace(`${shopifyStore}.myshopify.com`, shopifyStoreDomain) : "")

  const [checkoutCreate] = useMutation(CHECKOUT_CREATE)
  const [checkoutAttributeUpdate] = useMutation(CHECKOUT_ATTRIBUTES_UPDATE)
  const [checkoutCustomerAssociate] = useMutation(CHECKOUT_CUSTOMER_ASSOCIATE)
  const [checkoutShippingAddressUpdate] = useMutation(CHECKOUT_SHIPPING_ADDRESS_UPDATE)
  const [checkoutDiscountApply] = useMutation(CHECKOUT_DISCOUNT_APPLY)
  const [checkoutGiftcardAppend] = useMutation(CHECKOUT_GIFTCARDS_APPEND)
  const [checkoutShippingLineUpdate] = useMutation(CHECKOUT_SHIPPING_LINE_UPDATE)

  const { refetch: getCheckoutQuery } = useQuery(GET_CHECKOUT, { fetchPolicy: "no-cache", skip: true })
  const { refetch: getShippingRatesQuery } = useQuery(GET_SHIPPING_RATES, { fetchPolicy: "no-cache", skip: true })

  const addSentryBreadcrumb = useCallback(
    (extra = {}) => {
      Sentry.addBreadcrumb({
        type: "info",
        category: "hooks/useCheckout",
        data: {
          id: checkout?.id,
          currency: checkout?.currencyCode,
          lineItems: checkout?.lineItems,
          shippingLine: checkout?.shippingLine,
          customAttributes: checkout?.customAttributes,
          ...extra,
        },
      })
    },
    [checkout]
  )

  const getCheckout = useCallback(
    async (save: boolean) => {
      try {
        if (checkoutId) {
          const {
            data: { node: checkout },
          } = await getCheckoutQuery({
            countryCode,
            checkoutId,
          })

          if (save) {
            setCheckout(checkout)
          }

          return checkout
        }
        return false
      } catch (error) {
        Sentry.captureException(error)
      }
    },
    [checkoutId, countryCode, getCheckoutQuery]
  )

  const setCheckout = useCallback(
    checkout => {
      try {
        saveCheckout(checkoutNormaliser(checkout))
        setRawCheckout(checkout)
        storage.set(keys?.checkout, checkout?.id)
      } catch (error) {
        Sentry.captureException(error)
      }
    },
    [saveCheckout, keys, setRawCheckout]
  )

  const createCheckout = useCallback(
    async (buyerIdentity = countryCode, forceNew = false) => {
      addSentryBreadcrumb({
        countryCode,
        forceNew,
      })

      try {
        const existingCheckout = !forceNew && (await getCheckout(false))

        if (forceNew || !existingCheckout?.id || existingCheckout?.completedAt !== null || Object.keys(existingCheckout).length < 1) {
          const {
            data: {
              checkoutCreate: { checkout },
            },
          } = await checkoutCreate({
            variables: {
              countryCode,
              input: {
                allowPartialAddresses: true,
                buyerIdentity: { countryCode: buyerIdentity },
                customAttributes: [
                  {
                    key: keys?.website,
                    value: url,
                  },
                ],
              },
            },
          })
          if (checkout) {
            addSentryBreadcrumb()
            setCheckout(checkout)
          }
        } else {
          setCheckout(existingCheckout)
        }
        getShop()
      } catch (error) {
        Sentry.captureException(error)
        storage.remove(keys?.checkout)
      }
    },
    [countryCode, getCheckout, setCheckout, checkoutCreate, getShop]
  )

  const updateAttributes = useCallback(
    async input => {
      if (!checkoutId) {
        return
      }
      setLoadingAttributes(true)
      setUpdatedAttributes(false)
      addSentryBreadcrumb(input)
      const {
        data: { checkoutAttributesUpdateV2: data },
      } = await checkoutAttributeUpdate({
        variables: {
          countryCode,
          checkoutId,
          input,
        },
      })
      if (data) setCheckout(data?.checkout)
      setUpdatedAttributes(true)
      setLoadingAttributes(false)
    },
    [checkoutId, countryCode, checkoutAttributeUpdate, setCheckout, setLoadingAttributes]
  )

  const updateCustomer = useCallback(
    async customerAccessToken => {
      const {
        data: { checkoutCustomerAssociateV2: data },
      } = await checkoutCustomerAssociate({
        variables: {
          countryCode,
          checkoutId,
          customerAccessToken,
        },
      })
      setCheckout(data?.checkout)
    },
    [countryCode, checkoutCustomerAssociate, setCheckout]
  )

  const updateShippingAddress = useCallback(
    async input => {
      addSentryBreadcrumb({
        country: input?.country,
      })
      const {
        data: { checkoutShippingAddressUpdateV2: data },
      } = await checkoutShippingAddressUpdate({
        variables: {
          countryCode,
          checkoutId,
          shippingAddress: {
            firstName: input?.firstName,
            lastName: input?.lastName,
            address1: input?.address1,
            address2: input?.address2,
            city: input?.city,
            country: input?.country,
            phone: input?.phone,
            province: input?.province,
            zip: input?.zip,
          },
        },
      })
      if (!data.checkoutUserErrors.length) {
        setCheckout(data?.checkout)
      } else {
        setErrors(data.checkoutUserErrors)
      }
    },
    [checkoutId, countryCode, checkoutShippingAddressUpdate, countryCode, setCheckout]
  )

  const applyDiscountCode = useCallback(
    async discountCode => {
      setLoading(true)
      const {
        data: { checkoutDiscountCodeApplyV2: data },
      } = await checkoutDiscountApply({
        variables: {
          countryCode,
          checkoutId,
          discountCode,
        },
      })
      setLoading(false)
      if (!data.checkoutUserErrors.length) {
        storage.set(
          keys?.discounts,
          [...(storage.get(keys?.discounts) || []), discountCode].filter((value, index, self) => self.indexOf(value) === index)
        )
        setCheckout(data?.checkout)
      } else {
        setErrors(data.checkoutUserErrors)
      }
    },
    [countryCode, checkoutDiscountApply, setCheckout]
  )

  const applyGiftCardCode = useCallback(
    async giftCardCode => {
      const {
        data: { checkoutGiftCardsAppend: data },
      } = await checkoutGiftcardAppend({
        variables: {
          countryCode,
          checkoutId,
          giftCardCodes: [giftCardCode],
        },
      })

      if (!data.checkoutUserErrors.length) {
        setCheckout(data.checkout)
        return this
      } else {
        return data
      }
    },
    [countryCode, checkoutGiftcardAppend, setCheckout]
  )

  const getShippingRates = useCallback(async () => {
    if (!checkoutId) {
      return
    }

    const {
      data: { node: checkout },
    } = await getShippingRatesQuery({
      countryCode,
      checkoutId,
    })

    setCheckout(checkout)
  }, [checkoutId, countryCode, getShippingRatesQuery, setCheckout])

  const applyDiscounts = useCallback(async () => {
    const discountCodes = storage.get(keys?.discounts) || []
    for (const discountCode of discountCodes) {
      await applyDiscountCode(discountCode)
    }
  }, [applyDiscountCode])

  const duplicateCheckoutByCountryCode = useCallback(
    async countryCode => {
      clearAddress()
      const { customAttributes, lineItems, note, email } = checkout

      const discounts = storage.get(keys?.discounts) || []

      const mappedLineItems = lineItems?.map(item => ({
        variantId: item?.variant.id,
        quantity: item?.quantity || 1,
        customAttributes: item?.customAttributes?.map(({ key, value }) => ({ key, value })) || [],
      }))

      const {
        data: { checkoutCreate: data },
      } = await checkoutCreate({
        variables: {
          countryCode,
          input: {
            ...(email && { email }),
            ...(note && { note }),
            ...(customAttributes && {
              customAttributes:
                customAttributes?.map(({ key, value }) => ({
                  key,
                  value,
                })) || [],
            }),
            buyerIdentity: { countryCode },
            lineItems: mappedLineItems || [],
            allowPartialAddresses: true,
          },
        },
      })

      if (!discounts.length) setCheckout(data?.checkout)
      if (discounts.length) applyDiscounts()
    },
    [checkout, clearAddress, countryCode, checkoutCreate, setCheckout, applyDiscounts]
  )

  const getFormattedSalePriceFromTagOrRange = useCallback(
    (presentmentPrice: MoneyV2 | SearchResultProductPresentmentPriceRanges, saleTag: string, compareAtPrice: MoneyV2 = null, variants = []) => {
      const { currencyCode } = checkout || {}
      const prefix = currencyCode
      const pricing = {
        price: null,
        salePrice: null,
      }

      if (presentmentPrice) {
        if (!("min_variant_price" in presentmentPrice)) {
          const price = parseFloat(presentmentPrice?.amount)
          const compareAt = parseFloat(compareAtPrice?.amount)
          pricing.price = compareAt > price ? compareAt : price
          pricing.salePrice = compareAt > price ? price : null
        } else {
          const price = presentmentPrice.min_variant_price?.find(item => item?.currency_code === currencyCode)?.amount
          const compareAt = variants?.reduce((result, variant) => {
            return variant?.presentment_prices
              ?.filter(price => {
                return price?.compare_at_price?.currency_code === currencyCode
              })
              ?.reduce((prev, curr) => {
                return Math.max(curr?.compare_at_price?.amount || 0, prev)
              }, result)
          }, 0)
          pricing.price = compareAt > price ? compareAt : price
          pricing.salePrice = compareAt > price ? price : null
        }
      }

      if (isNaN(pricing.price)) {
        return {
          price: undefined,
          salePrice: undefined,
        }
      }

      if (!isSale || !saleTag) {
        return {
          price: `${prefix}${formatCurrency(Math.ceil(pricing.price), currencyCode, 0)}`,
          salePrice: pricing.salePrice > 0 ? `${prefix}${formatCurrency(Math.ceil(pricing.salePrice), currencyCode, 0)}` : undefined,
        }
      }

      const saleType = saleTag.split(":")[1]
      const saleNumber = saleTag.split(":")[3]

      switch (saleType) {
        case "percent":
          pricing.salePrice = pricing.price * (1 - Number(saleNumber) / 100)
          break
        case "price":
        default:
          pricing.salePrice = Number(saleNumber)
          break
      }

      if (isNaN(pricing.salePrice)) {
        return {
          price: `${prefix}${formatCurrency(Math.ceil(pricing.price), currencyCode, 0)}`,
        }
      }

      const salePrice = `${prefix}${
        saleType === "percent"
          ? formatCurrency(pricing.salePrice.toFixed(2), currencyCode, 0)
          : formatCurrency(Math.ceil(pricing.salePrice), currencyCode, 0)
      }`
      const price = `${prefix}${formatCurrency(Number(pricing.price), currencyCode, 0)}`

      return {
        salePrice,
        price,
      }
    },
    [checkout, isSale]
  )

  const updateShippingLine = useCallback(
    async (shippingRateHandle: string) => {
      const {
        data: { checkoutShippingLineUpdate: data },
      } = await checkoutShippingLineUpdate({
        variables: {
          countryCode,
          checkoutId,
          shippingRateHandle,
        },
      })
      if (!data.checkoutUserErrors.length) {
        setCheckout(data?.checkout)
      } else {
        setErrors(data.checkoutUserErrors)
      }
    },
    [checkoutId, countryCode, setCheckout, setErrors]
  )

  const calculateLineItemsQuantityTotal = useCallback(() => {
    return checkout?.lineItems?.reduce((acc, curr) => {
      const quantity = curr?.customAttributes?.find(item => item.key === keys?.accessory_line_item_link) ? 0 : curr.quantity
      return acc + quantity
    }, 0)
  }, [checkout])

  return {
    applyDiscountCode,
    applyGiftCardCode,
    calculateLineItemsQuantityTotal,
    checkout,
    checkoutUrl,
    createCheckout,
    duplicateCheckoutByCountryCode,
    errors,
    getCheckout,
    getFormattedSalePriceFromTagOrRange,
    getShippingRates,
    loading,
    loadingAttributes,
    rawCheckout,
    setCheckout,
    setRawCheckout,
    updateAttributes,
    updateCustomer,
    updatedAttributes,
    updateShippingAddress,
    updateShippingLine,
  }
}
