import { useSession } from "~/hooks/useSession"

/**
 * This component simply calls the useSession hook, such that the /api/session endpoint
 * is called at least once on page load.
 *
 * This ensures that the customers server-side cookies are set correctly from their legacy
 * access token if needed, and available for future API requests.
 *
 * @todo This can be removed when legacy access tokens are no longer needed, as all customers
 * will have logged in using the server-side endpoint, thus are guaranteed to have a cookie.
 */
export function SessionConsumer() {
  useSession()
  return null
}
