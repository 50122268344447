import { InternalType, LinkType } from "@bared/groq"
import { useAnnouncement } from "@hooks/useAnnouncement"
import { ComponentProps } from "@ts/components"
import { Announcement } from "@ts/sanity"
import React, { FC, useEffect, useMemo, useState } from "react"

export type AnnouncementInputProps = ComponentProps

export type AnnouncementOutputProps = {
  items: Omit<Announcement, "_type" | "textLink"> &
    {
      textLink?: LinkType[] | InternalType[]
    }[]
  active: number
}

export const withAnnouncement = (Component: FC<AnnouncementOutputProps>) =>
  React.memo(({ name = "Announcement" }: AnnouncementInputProps) => {
    const { items, duration } = useAnnouncement()
    const [active, setActive] = useState(0)
    const length = items?.length - 1

    useEffect(() => {
      if (length) {
        const interval = setInterval(() => {
          setActive(active => (active === length ? 0 : active + 1))
        }, duration * 1000)
        return () => clearInterval(interval)
      }
    }, [duration, length])

    Component.displayName = name
    return useMemo(() => <Component active={active} items={items} />, [active, items])
  })
