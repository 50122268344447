import React from "react"
import type { WritableAtom } from "jotai"
import { useHydrateAtoms } from "jotai/utils"

export function AtomHydrator({
  children,
  atomValues,
  dangerouslyForceHydrate = false,
}: {
  children: React.ReactNode
  dangerouslyForceHydrate?: boolean
  atomValues: Iterable<readonly [WritableAtom<unknown, [any], unknown>, unknown]>
}) {
  useHydrateAtoms(new Map(atomValues), {
    dangerouslyForceHydrate,
  })

  return children
}
