import { navigate as gatsbyNavigate } from "gatsby"
import React, { useContext, createContext, useCallback, useMemo } from "react"

import { mapSiteToLegacyLocale, type LegacyLocale } from "~/lib/sites"

import { useRouteLoaderData } from "~/hooks/useRouteLoaderData"

import type { AsGraph, WithLocalisation } from "~/types/components"

interface LocalisationFilterOptions {
  language?: string
  includeBase?: boolean
  ignoreParents?: boolean
}

interface LegacyLocaleContext {
  /**
   * ====================
   * The attributes below were originally available via the useLocalisationContext() hook.
   * ====================
   */

  /**
   * Country code associated with the site.
   * This technically should be the actual country the viewer is in, but for backwards
   * compatibility, this uses the "primary" country code from the site.
   */
  contextCountry: string
  /**
   * Legacy projection of the "locale".
   * This used to be resolved from the config file but it is now resolved from the site
   * provided by the server.
   */
  currentLocale: LegacyLocale
  /**
   * Was used to determine if the API request was being made to get location from IP address.
   * No longer needed, as the viewer country is resolved on the server.
   */
  isResolvingPreferredLocale: false
  /**
   * Array of all sites mapped to legacy locale objects.
   * This used to be mapped from the old root config file.
   */
  localisation: LegacyLocale[]
  /**
   * Take a URL and "normalise" it with the current site prefix.
   */
  normaliseRoute: (url: string, newBaseRoute?: string) => string
  /**
   * Navigate to a page taking into account the current site prefix.
   */
  navigate: (url: string, options?: Record<string, string | boolean>) => void
  /**
   * Base path for the "preferred" locale.
   *
   * This used to be set when you clicked on a "go to X site" button where
   * it would be stored in local storage.
   *
   * You would be force redirected to your "preferred" locale whenever you
   * visited another locale.
   *
   * This sucks, so we don't do this anymore, but we have set it here for
   * backwards compatibility.
   */
  preferredLocaleBasePath: string
  /**
   * Used to be used to change the current "country" in the localisation provider.
   */
  setContextCountry: () => void
  /**
   * Used to be use to change the isResolvingPreferredLocale boolean.
   */
  setIsResolvingPreferredLocale: () => void
  /**
   * Was used to determine if the geolocation popup should be enabled.
   */
  enableGeolocation: boolean

  /**
   * ====================
   * The attributes below were originally available via the useLocalisation() hook.
   * ====================
   */

  /**
   * Map of base routes, keyed by "language code" i.e. locale identifier.
   * @example {"en-AU": "/au", "en-US": "/us"}
   */
  routeMap: Record<string, string>

  /**
   * Find first node from an array of edges with the given locale.
   */
  findNodeByLocale: <T>(nodes: AsGraph<WithLocalisation<T>>, language: string) => T

  /**
   * Filter the given nodes by the given locale.
   */
  filterNodesByLocale: <T>(nodes: WithLocalisation<T>[], options?: LocalisationFilterOptions) => T[]
}

const LegacyLocaleContext = createContext<LegacyLocaleContext | null>(null)

export interface LegacyLocaleProviderProps {
  children: React.ReactNode
}

/**
 * Provides context for the legacy locale hooks.
 */
export function LegacyLocaleProvider({ children }: LegacyLocaleProviderProps) {
  const { site, sites, localSite } = useRouteLoaderData("routes/$site")

  const { locale, localLocale, locales } = useMemo(
    () => ({
      locale: mapSiteToLegacyLocale(site),
      localLocale: mapSiteToLegacyLocale(localSite),
      locales: sites.map(site => mapSiteToLegacyLocale(site)),
    }),
    [site, localSite]
  )

  const baseRoute = locale.baseRoute
  const baseRoutes = locales.map(locale => locale.baseRoute)

  const routeMap = locales.reduce((prev, curr) => ({ ...prev, [curr.languageCode]: curr.baseRoute }), {} as LegacyLocaleContext["routeMap"])

  /**
   * Take a URL and "normalise" it with the current site prefix.
   */
  const normaliseRoute = useCallback(
    (url: string, newBaseRoute?: string) => {
      const normalisedBaseRoute = newBaseRoute === "" ? "/" : newBaseRoute
      const targetBaseRoute = normalisedBaseRoute || baseRoute
      const result = `${targetBaseRoute}${baseRoutes.reduce((prev, curr) => {
        return prev.replace(new RegExp(`^${curr}`), "")
      }, url)}`
      return result
    },
    [baseRoute, baseRoutes]
  )

  /**
   * Navigate to a page taking into account the current site prefix.
   */
  const navigate = useCallback(
    (url: string, options?: Record<string, string | boolean>): void => {
      const { force, ...rest } = options || {}

      if (force) {
        gatsbyNavigate(url, rest)
        return
      }

      gatsbyNavigate(normaliseRoute(url), rest)
    },
    [normaliseRoute]
  )

  /**
   * Find first node from an array of edges with the given locale.
   */
  const findNodeByLocale = useCallback<LegacyLocaleContext["findNodeByLocale"]>(
    // @ts-expect-error
    (nodes, language) => {
      if (!language) language = locale.languageCode

      if (nodes?.edges?.length === 1) {
        // @ts-expect-error
        return nodes.edges[0].node
      }

      const defaultNode = nodes?.edges?.find(({ node: { i18n_base } }) => i18n_base === null)
      const matchedOrDefaultNode = nodes?.edges?.find(({ node: { i18n_lang } }) => i18n_lang === language) || defaultNode
      return matchedOrDefaultNode?.node
    },
    [locale]
  )

  /**
   * Filter the given nodes by the given locale.
   */
  const filterNodesByLocale = useCallback<LegacyLocaleContext["filterNodesByLocale"]>(
    (nodes, options) => {
      const { language = locale.languageCode, includeBase = true, ignoreParents = false } = options || {}
      const parents = {}
      const filteredNodes = nodes?.filter(({ i18n_base, i18n_lang }) => {
        if (!includeBase) {
          return i18n_lang === language
        }

        if (i18n_base) {
          // There is an inconsistency between GROQ and GraphQL, GROQ (which we use for Search) uses _id, GraphQL uses id
          // @ts-expect-error
          parents[i18n_base._id || i18n_base.id] = true
        }

        return i18n_base === null || i18n_lang === language
      })

      if (ignoreParents) {
        // @ts-expect-error
        return filteredNodes?.filter(({ _id, id }) => !parents[_id || id])
      }

      return filteredNodes
    },
    [locale]
  )

  const value = useMemo<LegacyLocaleContext>(
    () => ({
      routeMap,
      navigate,
      normaliseRoute,
      findNodeByLocale,
      filterNodesByLocale,
      localisation: locales,
      enableGeolocation: true,
      setContextCountry: () => {},
      isResolvingPreferredLocale: false,
      setIsResolvingPreferredLocale: () => {},
      contextCountry: site.__primaryCountryCode,
      currentLocale: mapSiteToLegacyLocale(site),
      preferredLocaleBasePath: localLocale.baseRoute,
    }),
    [navigate, normaliseRoute, locales, site, localLocale]
  )

  return <LegacyLocaleContext.Provider value={value}>{children}</LegacyLocaleContext.Provider>
}

/**
 * Return context from LegacyLocaleProvider.
 */
export function useLegacyLocaleContext() {
  const context = useContext(LegacyLocaleContext)
  if (!context) throw new Error(`No legacy locale context. Ensure you have wrapped LegacyLocaleProvider.`)
  return context
}
