import { useKlaviyo } from "@hooks/useKlaviyo"
import { Customer } from "@ts/shopify-storefront"
import React, { useEffect, useMemo, useState, useCallback } from "react"

export const CustomerContext: any = React.createContext({})

export const CustomerProvider = ({ children }) => {
  const { identify, track } = useKlaviyo()

  const [customer, _setCustomer] = useState<{ status: "RESOLVED" | "UNRESOLVED"; value: Customer | null }>({
    value: null,
    status: "UNRESOLVED",
  })

  const [sessionId, _setSessionId] = useState<{ status: "RESOLVED" | "UNRESOLVED"; value: string | null }>({
    value: null,
    status: "UNRESOLVED",
  })

  /**
   * Decorator for the setCustomer method.
   *
   * We have deep logic directly calling the setCustomer method, going against the "top down"
   * principles of React.
   *
   * We should rebuild this provider properly such that data is only managed at the top and no
   * setters are passed down through context.
   *
   * Until then, we wrap the "setCustomer" method such that we can detect when it's called from
   * somewhere else in the site, and set the status properly.
   */
  const setCustomer = useCallback((customer: Customer | null) => {
    _setCustomer({ status: "RESOLVED", value: customer })
  }, [])

  /**
   * Another example that needs to be moved out of the useCustomer hook and into this provider.
   */
  const setSessionId = useCallback((sessionId: string) => {
    _setSessionId({ status: "RESOLVED", value: sessionId })
  }, [])

  const values = useMemo(
    () => ({
      setCustomer,
      customer: customer.value,
      customerStatus: customer.status,
      setSessionId,
      sessionId: sessionId.value,
      sessionIdStatus: sessionId.status,
    }),
    [customer, setCustomer, sessionId, setSessionId]
  )

  useEffect(() => {
    if (customer?.email) {
      identify(customer)
      track("Account Login", customer)
    }
  }, [customer])

  return <CustomerContext.Provider value={values}>{children}</CustomerContext.Provider>
}
