import { useMemo } from "react"

import type { Site } from "~/lib/sites"

/**
 * Given an href, return it localized to the given site.
 */
export function useSiteHref({ site, href }: { site: Site; href: string }) {
  return useMemo(() => {
    // Do not attempt to change external hrefs.
    if (href.startsWith("http")) return href

    /**
     * Replace a two character prefix like /au/ or /au with a single slash.
     * This regex matches substrings which either end in a slash, or straight after the prefix.
     */
    const globalHref = href.replace(/^\/[a-z]{2}(\/|$)/, "/")

    // Calculate local href.
    let localHref = (site.urlPrefix ? "/" + site.urlPrefix : "") + globalHref

    // Trim trailing slash if necessary.
    if (localHref.endsWith("/")) localHref = localHref.slice(0, -1)

    return localHref
  }, [href, site])
}
