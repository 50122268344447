import { atom } from "jotai"
import cookies from "js-cookie"
import { isPast } from "date-fns"
import ky, { HTTPError, Options } from "ky"
import { atomWithQuery, atomWithMutation } from "jotai-tanstack-query"

import { debug } from "~/lib/logger.client"
import { getLegacyLocalStorageItem, setLegacyLocalStorageItem } from "~/lib/storage.client"

import type { SessionResponse } from "~/api/session"

import type { LogoutResponse } from "~/api/logout"
import type { LoginRequest, LoginResponse } from "~/api/login"
import type { RegisterRequest, RegisterResponse } from "~/api/register"

const d = debug.extend("atoms:session")

/**
 * If the user has an access token in their local storage, set
 * the legacy-customer-access-token cookie, so that the session
 * endpoint will keep the customer logged in, and return a fresh
 * HttpOnly cookie.
 *
 * @todo Remove when most legacy tokens have reasonably been migrated
 * to HttpOnly cookies. If a customer has not been migrated at removal
 * they will need to login again.
 */
function setLegacyAccessTokenCookie() {
  const item = getLegacyLocalStorageItem("headless:customer")
  if (!item || "object" !== typeof item) return

  const expiresAt = (item as any).expiresAt
  if (!expiresAt || "string" !== typeof expiresAt || isPast(expiresAt)) return

  const accessToken = (item as any).accessToken
  if (!accessToken || "string" !== typeof accessToken) return

  // Set session cookie, removed when browser closed.
  d("Resolved legacy customer access token, setting cookie.", { accessToken })
  cookies.set("legacy-customer-access-token", accessToken)
}

/**
 * Response from /api/session endpoint.
 */
export const sessionAtom = atomWithQuery(() => ({
  queryKey: ["session"],
  queryFn: async () => {
    setLegacyAccessTokenCookie()
    d("Sending request to /api/session.")
    const res = await ky.get("/api/session").json<SessionResponse>()
    d("Received response from /api/session", { res })
    return res
  },
}))

/**
 * Status of session.
 */
export const sessionStatusAtom = atom(get => {
  const { status } = get(sessionAtom)
  return status
})

/**
 * Customer from session.
 */
export const sessionCustomerAtom = atom(get => {
  const { data } = get(sessionAtom)
  return data?.customer ?? null
})

/**
 * Session ID from session.
 */
export const sessionIdAtom = atom(get => {
  const { data } = get(sessionAtom)
  return data?.sessionId ?? null
})

/**
 * Login a session.
 */
export const loginAtom = atomWithMutation(() => ({
  mutationKey: ["login"],
  mutationFn: async (data: LoginRequest) => {
    // Send API request.
    d("Sending request to /api/login.")
    const res = await ky.post("/api/login", { hooks, json: data }).json<LoginResponse>()
    d("Received response from /api/login", { res })

    // Get token from response.
    const { accessToken, expiresAt } = res

    // Set legacy local storage token.
    setLegacyLocalStorageItem("headless:customer", { accessToken, expiresAt })

    // Return response.
    return res
  },
}))

/**
 * Logout the current session.
 */
export const logoutAtom = atomWithMutation(() => ({
  mutationKey: ["logout"],
  mutationFn: async () => {
    // Send API request.
    d("Sending request to /api/logout.")
    const res = await ky.post("/api/logout", { hooks }).json<LogoutResponse>()
    d("Received response from /api/logout", { res })

    // Remove legacy token from local storage.
    localStorage.removeItem("headless:customer")

    // Return response.
    return res
  },
}))

/**
 * Register a new account.
 */
export const registerAtom = atomWithMutation(() => ({
  mutationKey: ["register"],
  mutationFn: async (data: RegisterRequest) => {
    // Send API request.
    d("Sending request to /api/register.")
    const res = await ky.post("/api/register", { hooks, json: data }).json<RegisterResponse>()
    d("Received response from /api/register", { res })

    // Get token from response.
    const { accessToken, expiresAt } = res

    // Set legacy local storage token.
    setLegacyLocalStorageItem("headless:customer", { accessToken, expiresAt })

    // Return response.
    return res
  },
}))

/**
 * Hooks for ky requests.
 */
const hooks: Options["hooks"] = {
  beforeError: [
    /**
     * Hook for extracting errors from responses.
     * @see https://github.com/sindresorhus/ky#hooksbeforeerror
     */
    async (error: HTTPError) => {
      try {
        const errorJson = await error.response.json()
        if (errorJson && "object" === typeof errorJson && "errors" in errorJson && Array.isArray(errorJson.errors)) {
          error.messages = errorJson.errors as string[]
        }
        return error
      } catch {
        return error
      }
    },
  ],
}

/**
 * Augment Error to include an optional array of messages.
 * This allows us to include multiple error messages in a single thrown error object.
 */
declare global {
  interface Error {
    messages?: string[]
  }
}
