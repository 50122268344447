import { atom } from "jotai"
import { createStorefrontApiClient } from "@shopify/storefront-api-client"

import { rootLoaderDataAtom } from "./loaders"

export const storefrontAtom = atom(get => {
  const { storefrontApiVersion, shop } = get(rootLoaderDataAtom)

  return createStorefrontApiClient({
    apiVersion: storefrontApiVersion,
    storeDomain: shop.shopifyDomain,
    publicAccessToken: shop.publicStorefrontToken,
  })
})
