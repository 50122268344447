import React, { FC } from "react"

import { useCurrency } from "../../hooks/useCurrency"
import { ComponentProps } from "../../types/components"
import { MoneyV2 } from "../../types/shopify-storefront"

export type PriceInputProps = ComponentProps & {
  compareAtPrice?: MoneyV2
  layout?: "product" | "cart" | "card" | "wishlist"
  price?: MoneyV2
}

export type PriceOutputProps = PriceInputProps & {
  currencyCode?: string
  onSale?: boolean
  comparedPrice?: string
}

export const withPrice =
  (Component: FC<PriceOutputProps>) =>
  ({ name = "Price", price, compareAtPrice, layout }: PriceInputProps) => {
    const { formatCurrency } = useCurrency()
    const { amount: priceAmount, currencyCode } = price || {}
    const { amount: compareAtPriceAmount } = compareAtPrice || {}

    const onSale = Number(compareAtPriceAmount) > Number(priceAmount)
    const formattedPrice = priceAmount ? formatCurrency(Number(priceAmount), currencyCode, 0) : ""
    const formattedCompareAtPrice = compareAtPriceAmount ? formatCurrency(Number(compareAtPriceAmount), currencyCode, 0) : ""

    Component.displayName = name
    return <Component onSale={onSale} price={formattedPrice} comparedPrice={formattedCompareAtPrice} currencyCode={currencyCode} layout={layout} />
  }
