import React, { useEffect } from "react"

import { Container } from "@bared/ui"

export interface RedirectProps {
  /** Href to redirect to */
  href: string
}

/**
 * This component is used when you have an href which you want the frontend
 * to redirect to. It shows a "Redirecting..." message.
 */
export function Redirect({ href }: RedirectProps) {
  // Hard navigate to href.
  useEffect(() => {
    window.location.href = href
  }, [href])

  return (
    <Container>
      <div className="flex justify-center py-peta">
        <p className="text-center">Redirecting...</p>
      </div>
    </Container>
  )
}
