import { useApolloClient, useLazyQuery, useMutation, useQuery } from "@apollo/client"
import * as Sentry from "@sentry/gatsby"
import {
  type NormalisedCollection,
  type NormalisedImage,
  type NormalisedProduct,
  type NormalisedProductVariant,
  type ProductStub,
} from "@ts/components"
import { MoneyV2, type Collection, type Metafield, type OrderLineItem, type CheckoutLineItem, type StoreAvailability } from "@ts/shopify-storefront"
import { useCallback } from "react"
import { useApp } from "./useApp"
import { useCheckoutContext } from "./useCheckout"
import { useLocalisationContext } from "./useLocalisation"
import { useLocation } from "./useLocation"
import { useSale } from "./useSale"

export const useShopify = () => {
  const client = useApolloClient()
  const { currentLocale, contextCountry } = useLocalisationContext()
  const { sale, isSaleActive } = useSale()
  const isOnSale = isSaleActive()
  const { checkout } = useCheckoutContext()
  const { locationCurrency } = useLocation()
  const {
    defaultCurrency,
    config: {
      settings: {
        keys: { accessory_line_item_link, cart_added_at },
      },
    },
  } = useApp()

  const productNormaliser = (product): NormalisedProduct => {
    const gender = !!product?.metafields ? product.metafields.find(metafield => metafield?.key?.includes("netsuite_gender")) : null
    const variants = sortVariantsBySize(
      edgeNormaliser<NormalisedProductVariant>(product?.variants)?.map(variant => {
        const preorderSize = product?.tags?.filter(tag => tag.includes(`preorder`)).map(item => item.split(`:`)[1])
        const variantSize = variant?.selectedOptions?.find(option => option?.name?.toLowerCase() === "size")?.value
        const isPreOrder: boolean = preorderSize?.reduce((prev, curr) => {
          return prev || curr === variantSize
        }, false)
        const badge = getBadgeFromTags(product?.tags)
        const isFinalSale: boolean = badge?.name === "Final Sale"

        return {
          ...variant,
          priceV2: variant?.price ?? priceNormaliser(variant?.priceV2),
          compareAtPriceV2: variant?.compareAtPrice ?? priceNormaliser(variant?.compareAtPriceV2),
          image: imageNormaliser(variant?.image),
          metafields: edgeNormaliser<Metafield>(variant?.metafields),
          storeAvailability: edgeNormaliser<StoreAvailability>(variant?.storeAvailability),
          isPreOrder,
          isFinalSale,
        }
      })
    )

    const availableForSale = !!variants.some(variant => !!variant.availableForSale)

    return {
      ...product,
      gender: gender?.value,
      variants,
      availableForSale,
      images: edgeNormaliser<NormalisedImage>(product?.images)?.map(image => imageNormaliser(image)),
      collections:
        edgeNormaliser<NormalisedCollection>(product?.collections)?.map(collection => ({
          ...collection,
          image: imageNormaliser(collection?.image),
        })) || [],
      metafields: edgeNormaliser<Metafield>(product?.metafields),
    }
  }

  const sortVariantsBySize = (variants: NormalisedProductVariant[]) => {
    return variants.sort((a, b) => {
      const sizeA = Number(a?.selectedOptions?.find(option => option?.name?.toLowerCase() === "size")?.value?.split("-")?.[0])
      const sizeB = Number(b?.selectedOptions?.find(option => option?.name?.toLowerCase() === "size")?.value?.split("-")?.[0])

      return sizeA - sizeB
    })
  }

  const sortLineItemsByDateAdded = (lineItems: CheckoutLineItem[]) => {
    if (!lineItems) {
      return
    }

    return lineItems.sort((a, b) => {
      const addedAtA = Number(a?.customAttributes?.find(({ key }) => key === cart_added_at)?.value)
      const addedAtB = Number(b?.customAttributes?.find(({ key }) => key === cart_added_at)?.value)

      return addedAtB - addedAtA
    })
  }

  const collectionNormaliser = collection => ({
    ...collection,
    image: imageNormaliser(collection?.image),
    metafields: edgeNormaliser<Collection>(collection?.metafields),
    products: edgeNormaliser<Metafield>(collection?.products).map(product => productNormaliser(product)),
  })

  const edgeNormaliser = <T>(object): T[] => {
    return object?.edges?.map(({ node }: { node: T }) => node) || object || []
  }

  const imageNormaliser = (image, size?) => ({
    alt: image?.altText || image?.alt || image?.asset?.alt || ``,
    src: imageUrl(image?.url || image?.originalSrc || image?.src || image?.asset?.url || ``, size),
    srcSet: imageSrcSets(image?.url || image?.originalSrc || image?.src || image?.asset?.url, size),
  })

  const priceNormaliser = (presentmentPrices?: MoneyV2 | string): MoneyV2 => {
    if (typeof presentmentPrices === "string") {
      return {
        amount: presentmentPrices,
        currencyCode: checkout?.currencyCode ?? locationCurrency,
      } as MoneyV2
    }

    return presentmentPrices as MoneyV2
  }

  const orderNormaliser = order => ({
    ...order,
    lineItems: edgeNormaliser<OrderLineItem>(order?.lineItems),
  })

  const filterLineItemsWithInactiveProducts = lineItems => {
    return lineItems?.filter(({ variant }) => !!variant?.product)
  }

  const checkoutNormaliser = checkout => {
    const edges = checkout?.lineItems?.edges
    const lineItems =
      sortLineItemsByDateAdded(
        filterLineItemsWithInactiveProducts(
          edges?.map(({ node: lineItem }) => ({
            ...lineItem,
            variant: {
              ...lineItem?.variant,
              isDiscounted: !!(
                lineItem?.discountAllocations?.length > 0 || lineItem?.variant?.compareAtPriceV2?.amount > lineItem?.variant?.priceV2?.amount
              ),
            },
          }))
        )
      ) || []

    const lineItemsProducts = lineItems?.filter(lineItem => {
      return !lineItem?.customAttributes?.find(({ key }) => key == accessory_line_item_link)
    })

    const lineItemsAccessoryMap = lineItems?.reduce((prev, curr) => {
      const attribute = curr?.customAttributes?.find(({ key }) => key === accessory_line_item_link)

      if (!attribute) {
        return prev
      }

      const prevMap = prev[attribute.value] || []

      return {
        ...prev,
        [attribute.value]: [...prevMap, curr],
      }
    }, {})

    return {
      ...checkout,
      lineItems,
      lineItemsProducts,
      lineItemsAccessoryMap,
    }
  }

  const parseShopifyRaw = (stub: ProductStub) => {
    const { shopify, ...rest } = stub
    if (!shopify?.raw) {
      return rest
    }
    try {
      return productNormaliser({ ...rest, ...JSON.parse(shopify?.raw) })
    } catch (e) {
      Sentry.captureException(e)
    }
    return rest
  }

  const imageUrl = (src: string, size = -1): string => {
    const dimensions = `${size}x${size}`
    const match = src?.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i)

    return match && src?.includes("shopify.com") && !src?.includes("res.cloudinary.com/baredfootwear/image/fetch/f_auto") && size !== -1
      ? `https://res.cloudinary.com/baredfootwear/image/fetch/f_auto/${src?.split(match[0])[0]}_${dimensions}${match[0]}`.replace(/http(s)?:/, ``)
      : src
  }

  const imageSrcSets = (src, size) =>
    src?.includes(`shopify.com`)
      ? [1, 150, 200, 400, 500, 768, 1000, 1500, 2000]
          .filter(set => !size || (size && size >= set))
          .map(set => `${imageUrl(src, set.toString())} ${set}w`)
          .join(`,`)
      : ""

  const decodeCollectionId = (id?: string) => {
    return id ? id.split(`Collection/`)[1] : id
  }

  const decodeProductId = (id?: string) => {
    return id ? id.split(`Product/`)[1] : id
  }

  const decodeVariantId = (id?: string) => {
    return id ? id.split(`ProductVariant/`)[1] : id
  }

  const decodeCustomerId = (id?: string) => {
    return id ? id.split(`Customer/`)[1] : id
  }

  const encodeProductId = (id?: string) => {
    return id ? `gid://shopify/Product/${id}` : id
  }

  const encodeCollectionId = (id?: string) => {
    return id ? `gid://shopify/Collection/${id}` : id
  }

  const encodeVariantId = (id?: string) => {
    return id ? `gid://shopify/ProductVariant/${id}` : id
  }

  const encodeCustomerId = (id?: string) => {
    return id ? `gid://shopify/Customer/${id}` : id
  }

  const formatProductTitle = (tags?: string[]) => {
    return tags?.find(tag => tag.includes("product_name"))?.split(":")[1]
  }

  const formatProductGroup = (tags?: string[]) => {
    return tags?.find(tag => tag.includes("RGroup"))
  }

  const getSaleTagFromTags = useCallback(
    (tags?: string[]): string | undefined => {
      return tags?.find(tag => tag.includes("sale") && tag.includes(checkout?.currencyCode))
    },
    [checkout]
  )

  const getSaleTagForDefaultCurrencyFromTags = useCallback(
    (tags?: string[]): string | undefined => {
      const { defaultCountryCode } = currentLocale || {}
      return tags?.find(tag => tag.includes("sale") && tag.includes(defaultCountryCode))
    },
    [currentLocale, defaultCurrency]
  )

  const getBadgeFromTags = useCallback(
    (tags?: string[], productStatus?: string, availableForSale?: boolean): Badge | undefined => {
      //Resolve notify me/out of stock badge based off inventory count and product status (Convert this to use availableForSale when possible)
      if (!availableForSale && productStatus) {
        return ["Seasonal", "Core"].includes(productStatus)
          ? { name: "Notify Me", variant: "secondary" }
          : { name: "Out Of Stock", variant: "primary" }
      }
      const badgeTags = tags?.filter(tag => tag?.startsWith("badge"))
      const { defaultCountryCode } = currentLocale

      if (!badgeTags?.length) {
        return
      }

      return badgeTags.reduce((prev: Badge | undefined, badgeTag) => {
        const badgeParts = badgeTag.split(":")
        let [, colour, name, countryCode, saleType] = badgeParts || []

        if (prev) {
          return prev
        }

        if (!name || !colour) {
          return prev
        }

        // If the "country code" part looks like a sale type, then it has been omitted.
        if (!!countryCode && ["vip", "sale"].includes(countryCode)) {
          saleType = countryCode
          countryCode = undefined
        }

        if (!!countryCode && defaultCountryCode?.localeCompare(countryCode) !== 0) {
          return prev
        }

        if ((isOnSale && saleType !== sale?.type) || (!isOnSale && !!saleType)) {
          return prev
        }

        return {
          name,
          colour,
          saleType,
          countryCode,
        }
      }, undefined)
    },
    [currentLocale, sale, isOnSale]
  )

  const createQueryFromTagPrefix = (prefix: string, tags: string[], queryPrefix: string) => {
    const tagsWithPrefix = tags?.filter(tag => tag.startsWith(prefix))
    const prefixWithDelimiter = `${prefix}:`
    const queryParts = tagsWithPrefix?.map(tag => `${queryPrefix}:'${tag.split(prefixWithDelimiter)[1]}'`)
    const query = queryParts?.join(" OR ")
    return query
  }

  const productsInCurrentLocale = products =>
    products?.filter(product => {
      if (product?.tags) {
        return product?.tags?.includes(`country:${contextCountry}`)
      }

      return product
    })

  return {
    checkoutNormaliser,
    client,
    collectionNormaliser,
    createQueryFromTagPrefix,
    decodeCollectionId,
    decodeCustomerId,
    decodeProductId,
    decodeVariantId,
    edgeNormaliser,
    encodeCollectionId,
    encodeCustomerId,
    encodeProductId,
    encodeVariantId,
    formatProductGroup,
    formatProductTitle,
    getBadgeFromTags,
    getSaleTagForDefaultCurrencyFromTags,
    getSaleTagFromTags,
    imageNormaliser,
    imageSrcSets,
    imageUrl,
    orderNormaliser,
    parseShopifyRaw,
    priceNormaliser,
    productNormaliser,
    productsInCurrentLocale,
    sortLineItemsByDateAdded,
    sortVariantsBySize,
    useLazyQuery,
    useMutation,
    useQuery,
  }
}
