import { useLegacyLocaleContext } from "~/providers/LegacyLocaleProvider"

/**
 * Legacy hook used for backwards compatibility.
 * @deprecated
 */
export function useLocalisationContext() {
  return useLegacyLocaleContext()
}

/**
 * Legacy hook used for backwards compatibility.
 * @deprecated
 */
export function useLocalisation() {
  return useLegacyLocaleContext()
}
