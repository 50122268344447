module.exports = [{
      plugin: require('../../node_modules/.pnpm/@sentry+gatsby@7.106.1_gatsby@5.13.3_react@18.3.1/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@5.13.3_patch_hash=6zrlgscmip7kno67loq73ogety_@babel+plugin-syntax-flow@7.25.7_@babel+p_katbpev72yqjixymau5erabjfq/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
