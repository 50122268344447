import { GET_PRODUCT_WITH_ACCESSORIES } from "@graphql/shopify"
import { useCore } from "@hooks/useCore"
import { useLocalisationContext } from "@hooks/useLocalisation"
import { DropdownOption, useSelector } from "@hooks/useSelector"
import { useShopify } from "@hooks/useShopify"
import { NormalisedProduct, NormalisedProductVariant, ProductStub } from "@ts/components"
import { Product } from "@ts/shopify-storefront"
import React, { Context, ReactNode, createContext, useMemo } from "react"

import { useStorefrontLegacyQuery } from "~/hooks/useStorefrontLegacy"

type ProductProviderProps = {
  children: ReactNode
  queryType?: "PDP" | "SUMMARY"
  stub?: ProductStub
}

export type ProductContext = {
  accessories: NormalisedProduct[]
  grouped: Product[]
  loading: boolean
  product: NormalisedProduct
  notify: boolean
  recommendations: Product[]
  selectedVariant?: NormalisedProductVariant
  selectedVariantAccessories?: NormalisedProductVariant[]
  dropdownOptions?: DropdownOption[]
  selectedValue?: string
  handleOptionChange: (size: string) => void
}

export const ProductContext: Context<ProductContext> = createContext<ProductContext>({})

export const ProductProvider = ({ children, stub, queryType = "SUMMARY" }: ProductProviderProps) => {
  const {
    graphql: {
      queries: { GET_PRODUCT, GET_PRODUCT_WITH_RECOMMENDATIONS, GET_PRODUCT_WITH_RECOMMENDATIONS_AND_ACCESSORIES },
    },
  } = useCore()
  const { contextCountry } = useLocalisationContext()
  const { createQueryFromTagPrefix, encodeProductId, edgeNormaliser, useQuery, parseShopifyRaw, productNormaliser } = useShopify()
  const normalisedStub = productNormaliser({
    images: [{ src: stub?.image }],
    handle: stub?.shopify?.handle ?? stub?.shopify?.shopifyHandle,
    id: stub?.shopify?.id ?? stub?.shopify?.shopifyId,
    ...parseShopifyRaw(stub),
  })
  const { id, tags, handle } = normalisedStub
  const accessoriesQuery = createQueryFromTagPrefix("accessory", tags, "title")
  const queries = {
    PDP: {
      query: accessoriesQuery ? GET_PRODUCT_WITH_RECOMMENDATIONS_AND_ACCESSORIES : GET_PRODUCT_WITH_RECOMMENDATIONS,
      variables: {
        accessoriesQuery,
        parentQuery: `tag:'country:${contextCountry}' AND tag:'colours:${handle}' AND tag_not:'reactify:unavailable'`,
      },
    },
    SUMMARY: {
      query: accessoriesQuery ? GET_PRODUCT_WITH_ACCESSORIES : GET_PRODUCT,
      variables: {
        accessoriesQuery,
        parentQuery: `tag:'parent:${handle}'`,
      },
    },
  }
  const { query, variables } = queries[queryType]
  const { data, loading } = useStorefrontLegacyQuery(query, {
    variables: {
      countryCode: contextCountry,
      id: encodeProductId(id as string),
      handle,
      firstImages: 10,
      firstMedia: 1,
      firstVariants: 50,
      firstCollections: 10,
      firstMetafields: 10,
      ...variables,
    },
  })

  const accessories =
    !loading && data?.accessories ? edgeNormaliser<Product>(data?.accessories).map(accessory => productNormaliser(accessory)) : undefined
  const grouped = !loading && data?.grouped ? edgeNormaliser<Product>(data?.grouped) : undefined
  const product = !loading && data?.product ? { ...normalisedStub, ...productNormaliser(data?.product) } : normalisedStub
  const { variants, gender } = product
  const recommendations = !loading && data?.recommendations ? edgeNormaliser<Product>(data?.recommendations) : undefined
  const isGiftCard = product?.tags?.includes("type:giftcard")
  const notify = ["Core", "Seasonal"].includes(product?.metafields?.find(metafield => metafield?.key === "netsuite_status")?.value ?? "")

  const { selectedVariant, selectedVariantAccessories, dropdownOptions, selectedValue, handleOptionChange } = useSelector({
    variants,
    accessories,
    gender,
    notify,
    loading,
    optionKey: isGiftCard ? "denominations" : "size",
    tags,
  })

  const values = useMemo(
    () => ({
      accessories,
      grouped,
      loading,
      product,
      notify,
      recommendations,
      selectedVariant,
      selectedVariantAccessories,
      dropdownOptions,
      selectedValue,
      handleOptionChange,
    }),
    [
      accessories,
      grouped,
      loading,
      product,
      notify,
      recommendations,
      selectedVariant,
      selectedVariantAccessories,
      dropdownOptions,
      selectedValue,
      handleOptionChange,
    ]
  )

  return <ProductContext.Provider value={values}>{children}</ProductContext.Provider>
}
