import { useCallback } from "react"

import { useApp } from "./useApp"
import { useSite } from "./useSite"
import { useViewerContext } from "./useViewerContext"

import { useRouteLoaderData } from "~/hooks/useRouteLoaderData"

/**
 * Legacy hook used for backwards compatibility.
 * @deprecated
 */
export function useLocation() {
  const { config } = useApp()

  const { shop } = useRouteLoaderData("root")

  const site = useSite()
  const viewerContext = useViewerContext()

  const userData = useCallback(async () => {
    const response = await fetch(config?.services?.location?.serviceUrl)
    const data = await response.json()

    return {
      ipData: data,
      userAgent: navigator.userAgent || "",
    }
  }, [config])

  return {
    userData,
    country: viewerContext.countryCode,
    checkoutCurrency: site.currencyCode,
    locationCurrency: site.currencyCode,
    /**
     * Shopify domain without the .myshopify.com part.
     * @example bared-footwear-au
     */
    shopifyStore: shop.shopifyDomain.replace(".myshopify.com", ""),
    /**
     * Despite the name, this is actually the checkout domain.
     * @example checkout.baredfootwear.com
     */
    shopifyStoreDomain: shop.checkoutDomain,
  }
}
